/* You can add global styles to this file, and also import other style files */

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&family=Poppins:wght@400;700;800&display=swap');
/* font-family: 'Montserrat', sans-serif; */
@import url('https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Outlined');

@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Montserrat';
}

.onetalent-container {
  max-width: 1280px;
  margin: auto;
  @apply px-4;
}

.svg-chart-container {
  height: 100vh;
}

.mdc-circular-progress__indeterminate-circle-graphic {
  stroke: #e3405c !important;
}
